import { useContext, useEffect } from 'react';
import { featureFlagsContext } from '../providers/FeatureFlagsProvider';
import useAuth from './useAuth';

export const usePlannedDownTimeRedirect = () => {
  const { isPlannedDownTime } = useContext(featureFlagsContext);
  const { signOut } = useAuth();

  useEffect(() => {
    if (isPlannedDownTime) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      signOut();
    }
  }, [isPlannedDownTime]);

  return { isPlannedDownTime: isPlannedDownTime };
};
