import { Router, useLocation } from '@reach/router';
import React from 'react';

import ROUTES from '../../routes';
import AccountPickerPage from './account-picker';
import { usePlannedDownTimeRedirect } from '../../hooks/usePlannedDownTimeRedirect';
import Helmet from 'react-helmet';
import { useTranslation } from '../../hooks/useTranslation';

export interface RegistrationPageProps {
  path: string;
}

export const RegistrationPages = () => {
  const { t } = useTranslation();
  usePlannedDownTimeRedirect();

  const location = useLocation();
  const locationState = location.state as any;
  const redirectUrl = locationState?.redirectUrl;

  return (
    <>
      <Helmet>
        <title>{t('REGISTRATION')}</title>
      </Helmet>
      <Router basepath={ROUTES.REGISTRATION}>
        {/* 
        The original implmentation is a little confusing...
        All of the 'child compoents' referenced here are actually first-class pages with routes created 
        by GatsbyJS at build time. This is because all of the compent files exist in the /src/pages/ directory:
        https://www.gatsbyjs.com/docs/recipes/pages-layouts/#creating-pages-automatically

        For this one it appears that the route being overridden and rendered as a child-component, but as noted above
        since it is a file in the pages directory, it is also routed by GasbyJS and available at /register/account-picker
        */}
        <AccountPickerPage path={'/'} redirectUrl={redirectUrl} />
        {/* 
        These pages aren't being routed here. They are files in the pages
        directory, so GatsbyJS is creating top-level routing for them.
        This file isn't invloved in rendering them.

        <CreateResidentialPage path={ROUTES.REGISTRATION_CREATE_RESIDENTIAL} />
        <CreateBusinessPage path={ROUTES.REGISTRATION_CREATE_BUSINESS} />
        <RegistrationCompletePage path={ROUTES.REGISTRATION_COMPLETE} /> 
        */}
      </Router>
    </>
  );
};

export default RegistrationPages;
